import React, { createContext, useState } from 'react';
import type { FC, ReactNode } from 'react';

import { useAuth } from 'hooks';
import type { Teacher } from 'types';

// Teacher context type
export interface TeacherContextType {
  profile: Teacher;
  logout: VoidFunction;
  refetch: VoidFunction;
}

// Instantiate teacher context
export const TeacherContext = createContext<TeacherContextType | null>(null);

// TeacherProvider component prop types
interface TeacherProviderProps {
  children: ReactNode;
}

/**
 * Provides React context controlling profile for authenticated teacher.
 */
const TeacherProvider: FC<TeacherProviderProps> = ({
  children,
}: TeacherProviderProps) => {
  // Select AuthContext
  const authContext = useAuth()!;
  const user = authContext.data.user as Teacher;

  // Instantiate parent profile
  const [profile] = useState<TeacherContextType['profile']>(user);

  return (
    <TeacherContext.Provider
      value={{
        profile,
        logout: authContext.logout,
        refetch: authContext.refetch,
      }}
    >
      {children}
    </TeacherContext.Provider>
  );
};

export default TeacherProvider;

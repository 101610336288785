import React from 'react';
import type { FC } from 'react';

import styled from '@emotion/styled';

import colors from 'theme/colors';
import { RouterLink } from './Links';
import type { RouterLinkProps } from './Links';

// NavLink component prop types
export interface NavLinkProps extends RouterLinkProps {
  active?: boolean;
}

// Underline hover effect
const StyledLink = styled(RouterLink as FC<NavLinkProps>)(
  ({ active }) => `
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -12px;
    width: 100%;
    height: 4px;
    background-color: ${colors.orange['500']};
    transform-origin: center;
    transform: translate(-50%, 0) scaleX(${active ? 1 : 0});
    transition: transform 0.3s ease-in-out;
  }

  &:hover::before {
    transform: translate(-50%, 0) scaleX(1);
  }
`
);

/**
 * Navigation menu link
 */
const NavLink: FC<NavLinkProps> = ({
  active = false,
  children,
  ...rest
}: NavLinkProps) => (
  <StyledLink
    color="teal.500"
    fontWeight="bold"
    fontSize={['md', null, 'lg']}
    w="min-content"
    _hover={{}}
    active={active}
    {...rest}
  >
    {children}
  </StyledLink>
);

export default NavLink;

import React from 'react';
import type { FC } from 'react';
import styled from '@emotion/styled';
import colors from 'theme/colors';

// LoadingSpinner component prop types
export interface LoadingSpinnerProps {
  size?: string | number;
  borderWidth?: string | number;
}

/**
 * Custom styled loading spinner
 */
const Spinner = styled.div<LoadingSpinnerProps>(
  ({ size, borderWidth }: LoadingSpinnerProps) => `
  height: ${size};
  width: ${size};
  border: ${borderWidth} solid ${colors.orange['200']};
  border-radius: 50%;
  border-top-color: ${colors.orange['500']};
  animation: rotate 1s 0s infinite linear normal;

  &::after {
    content: '';
    height: 60%;
    width: 60%;
    display: block;
    margin: 10px auto;
    border: inherit;
    border-radius: inherit;
    border-top-color: inherit;
    animation: rotate 0.5s 0s infinite linear reverse;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
);

/**
 * Animated loading spinner
 */
const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  size = '3rem',
  borderWidth = '4px',
}: LoadingSpinnerProps) => (
  <Spinner
    size={typeof size === 'number' ? `${size}px` : size}
    borderWidth={
      typeof borderWidth === 'number' ? `${borderWidth}px` : borderWidth
    }
  />
);

export default LoadingSpinner;

import React, { memo } from 'react';
import type { FC } from 'react';

import { Text } from '@chakra-ui/core';
import type { BoxProps } from '@chakra-ui/core';

// ErrorMessage component prop types
export interface ErrorMessageProps extends BoxProps {
  isActive?: boolean;
}

/**
 * Memoized error text for input forms
 */
const ErrorMessage: FC<ErrorMessageProps> = ({
  isActive,
  children,
  ...rest
}: ErrorMessageProps) =>
  !isActive ? null : (
    <Text w={['95%', 'auto']} color="red.500" textAlign="center" {...rest}>
      {children}
    </Text>
  );

export default memo(ErrorMessage);

import React, { Component } from 'react';
import type { ReactNode } from 'react';
import ErrorPage from 'pages/400';

// ErrorBoundary component prop types
interface ErrorBoundaryProps {
  children?: ReactNode;
}

// ErrorBoundary state type
interface ErrorBoundaryState {
  error: Error | null;
}

/**
 * Error catch-all
 */
export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error };
  }

  public render(): ReactNode {
    return this.state.error ? <ErrorPage /> : this.props.children;
  }
}

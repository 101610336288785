import React from 'react';
import type { FC } from 'react';

import { Input } from '@chakra-ui/core';
import type { InputProps } from '@chakra-ui/core';
import styled from '@emotion/styled';

import colors from 'theme/colors';

// Override Chakra-UI styles
const StyledInput = styled(Input)`
  &::placeholder {
    color: ${colors.teal['500']};
    opacity: 0.36;
  }
`;

// EinsteinInput component prop types
export type EinsteinInputProps = InputProps;

/**
 * Custom styled Chakra-UI Input component
 */
const EinsteinInput: FC<EinsteinInputProps> = ({
  ...rest
}: EinsteinInputProps) => (
  <StyledInput
    focusBorderColor="orange.500"
    lineHeight="normal"
    borderColor="gray.500"
    rounded={8}
    _hover={{ borderColor: 'gray.700' }}
    {...rest}
  />
);

export default EinsteinInput;

import React from 'react';
import type { FC, ReactNode } from 'react';
import { Router } from '@reach/router';
import type { RouterProps } from '@reach/router';

import NotFoundPage from 'pages/404';
import { Route } from './Routes';

// EinsteinRouter component prop types
export interface EinsteinRouterProps extends RouterProps {
  children: ReactNode;
}

/**
 * Reach Router with default 404 page baked in
 */
const EinsteinRouter: FC<EinsteinRouterProps> = ({
  children,
  ...rest
}: EinsteinRouterProps) => (
  <Router {...rest}>
    {children}
    <Route default component={NotFoundPage} />
  </Router>
);

export default EinsteinRouter;

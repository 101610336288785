import React from 'react';
import type { FC } from 'react';
import type { FieldProps } from 'formik';

import styled from '@emotion/styled';
import ReactDatePicker from 'react-datepicker';
import type { ReactDatePickerProps } from 'react-datepicker';

import colors from 'theme/colors';

// EinsteinDatePicker component prop types
export interface EinsteinDatePickerProps
  extends Omit<ReactDatePickerProps, 'onChange'> {
  name: FieldProps['field']['name'];
  setFieldValue: FieldProps['form']['setFieldValue'];
  isInvalid: boolean;
}

// Custom styled DatePicker
const StyledDatePicker = styled(ReactDatePicker)(
  ({ isInvalid }: any) => `
  border: 1px solid;
  border-color: ${isInvalid ? colors.red['500'] : colors.gray['500']};
  box-shadow: ${isInvalid ? `0 0 0 1px ${colors.red['500']}` : 'none'};
  border-radius: 8px;
  padding: 0.5rem;
  text-align: center;
  transition: all 0.2s;
  width: 100%;

  &:focus {
    border-color: ${isInvalid ? colors.red['500'] : colors.orange['500']};
    box-shadow: 0 0 0 1px ${
      isInvalid ? colors.red['500'] : colors.orange['500']
    };
  }

  &::placeholder {
    color: ${colors.teal['500']};
    opacity: 0.36;
  }
`
);

/**
 * Custom styled and Formik-enabled ReactDatePicker component
 */
const EinsteinDatePicker: FC<EinsteinDatePickerProps> = ({
  name,
  value,
  setFieldValue,
  ...props
}: EinsteinDatePickerProps) => {
  const selectedDate = (value && new Date(value)) || null;

  return (
    <StyledDatePicker
      {...props}
      popperPlacement="bottom"
      dateFormat="MM/dd/yyyy, h:mm aa"
      showTimeSelect
      timeFormat="HH:mm"
      timeCaption="time"
      showPopperArrow={false}
      selected={selectedDate}
      onChange={(val: any): void => {
        if (val) setFieldValue(name, val.toISOString());
      }}
    />
  );
};

export default EinsteinDatePicker;

import React from 'react';
import type { FC } from 'react';

import { Box } from '@chakra-ui/core';

import ErrorBoundary from 'components/ErrorBoundary';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';

/**
 * Creates a new component that wraps received component
 * with semantic HTML, Navbar, Footer & ErrorBoundary.
 *
 * @param WrappedComponent - Component to wrap with layout.
 */
const withLayout = <WrappedComponentProps extends {}>(
  WrappedComponent: FC<WrappedComponentProps>
): FC<WrappedComponentProps> => {
  const WithLayout: FC<WrappedComponentProps> = ({
    ...wrappedComponentProps
  }: WrappedComponentProps) => (
    <main>
      <ErrorBoundary>
        <header>
          <Navbar
            h={['82px', '84px', '86px', '90px']}
            borderBottom={['none', null, '1px solid']}
            borderColor="gray.500"
          />
        </header>
        <Box
          px={[4, 8, 16, 32]}
          py={8}
          minH={[
            'calc(100vh - 164px)',
            'calc(100vh - 168px)',
            'calc(100vh - 172px)',
            'calc(100vh - 180px)',
          ]}
          backgroundColor={['#efeeee', null, '#fff']}
        >
          <WrappedComponent {...wrappedComponentProps} />
        </Box>
        <footer>
          <Footer h={['82px', '84px', '86px', '90px']} />
        </footer>
      </ErrorBoundary>
    </main>
  );

  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithLayout.displayName = `withLayout(${wrappedComponentName})`;
  return WithLayout;
};

export default withLayout;

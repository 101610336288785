import React from 'react';
import type { FC } from 'react';

import { Box, Flex, Link, Text } from '@chakra-ui/core';

import EinsteinStudiosLogoFlat from 'assets/svgs/einstein-studios-logo-flat.svg';
import ErrorBoundary from 'components/ErrorBoundary';

/**
 * Creates a new component that wraps received component
 * with semantic HTML, Navbar, Footer & ErrorBoundary.
 *
 * @param WrappedComponent - Component to wrap with layout.
 *
 * Essentially, a withLayout copy, but specific to onboarding.
 */
const withOnboardingLayout = <
  WrappedComponentProps extends Record<string, unknown>
>(
  WrappedComponent: FC<WrappedComponentProps>
): FC<WrappedComponentProps> => {
  const WithOnboardingLayout: FC<WrappedComponentProps> = ({
    ...wrappedComponentProps
  }: WrappedComponentProps) => (
    <main>
      <ErrorBoundary>
        <header>
          <Flex
            w="100%"
            h={['82px', '84px', '86px', '90px']}
            px={[4, 8, 16, 32]}
            align="center"
            justify="space-between"
          >
            <Link
              d="flex"
              w="150px"
              alignItems="center"
              href={process.env.LANDING_PAGE_URL}
            >
              <EinsteinStudiosLogoFlat />
            </Link>
          </Flex>
        </header>
        <Box
          px={[4, 8, 16, 32]}
          minH={[
            'calc(100vh - 164px)',
            'calc(100vh - 168px)',
            'calc(100vh - 172px)',
            'calc(100vh - 180px)',
          ]}
        >
          <WrappedComponent {...wrappedComponentProps} />
        </Box>
        <footer>
          <Flex
            w="100%"
            align="center"
            justify="center"
            color="teal.500"
            h={['82px', '84px', '86px', '90px']}
          >
            <Text w={['70%', '100%']} textAlign="center">
              Stuck? Any questions? Call us at 650-466-8512.
            </Text>
          </Flex>
        </footer>
      </ErrorBoundary>
    </main>
  );

  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithOnboardingLayout.displayName = `withOnboardingLayout(${wrappedComponentName})`;
  return WithOnboardingLayout;
};

export default withOnboardingLayout;

import { graphql, useStaticQuery } from 'gatsby';

/**
 * Generates graphql query for subject images.
 */
const useSubjectImages = (): any =>
  useStaticQuery(graphql`
    query {
      spanishImage: file(relativePath: { eq: "images/spanish.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      literacyImage: file(relativePath: { eq: "images/literacy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

export default useSubjectImages;

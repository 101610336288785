import type { Subject, Weekday } from './types';

export const spanish: Subject = {
  id:
    process.env.NODE_ENV === 'production'
      ? '93f0e512-4964-11ea-960b-067d4d266f89'
      : '662ec4c9-4df8-11ea-be37-020ec575098b',
  name: 'Spanish',
  description:
    'Spanish is the second most-used language worldwide with over 480 million native speakers, over 38 million of whom live in the US, where Spanish is the most popular second language learned.',
  picture:
    'https://einstein-studios-s3-bucket.s3-us-west-1.amazonaws.com/subjects/spanish.png',
};

export const literacy: Subject = {
  id:
    process.env.NODE_ENV === 'production'
      ? '5f08b8f9-59bf-11ea-960b-067d4d266f89'
      : '1d145c0b-5296-11ea-be37-020ec575098b',
  name: 'Literacy',
  description:
    'Structured literacy classes are taught by trained and experienced Orton-Gillingham tutors, a research-based, multi-sensory approach that will help your student become a better reader and writer.',
  picture:
    'https://einstein-studios-s3-bucket.s3-us-west-1.amazonaws.com/subjects/literacy.jpg',
};

// Enumerates weekday strings to integer days of week
export enum WeekdaysEnum {
  SUNDAY = 0,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

// Days of week strings
export const WEEKDAYS: {
  code: Weekday;
  long: string;
  abbreviation: string;
}[] = [
  { code: 'SUNDAY', long: 'Sunday', abbreviation: 'Sun.' },
  { code: 'MONDAY', long: 'Monday', abbreviation: 'Mon.' },
  { code: 'TUESDAY', long: 'Tuesday', abbreviation: 'Tues.' },
  { code: 'WEDNESDAY', long: 'Wednesday', abbreviation: 'Wed.' },
  { code: 'THURSDAY', long: 'Thursday', abbreviation: 'Thurs.' },
  { code: 'FRIDAY', long: 'Friday', abbreviation: 'Fri.' },
  { code: 'SATURDAY', long: 'Saturday', abbreviation: 'Sat.' },
];

import React from 'react';
import type { FC, ReactElement } from 'react';

import { Box, Heading, PseudoBox, Stack } from '@chakra-ui/core';
import type { HeadingProps, PseudoBoxProps, StackProps } from '@chakra-ui/core';

// SectionTitle component prop types
export interface SectionTitleProps extends PseudoBoxProps {
  size?: HeadingProps['size'];
  avatar?: ReactElement;
}

/**
 * Full-width, bottom-border section title
 */
export const SectionTitle: FC<SectionTitleProps> = ({
  size = 'md',
  avatar,
  children,
  ...rest
}: SectionTitleProps) => (
  <PseudoBox
    w="100%"
    borderBottom="1px solid"
    borderColor="gray.500"
    mb={4}
    {...rest}
  >
    <Heading
      d="flex"
      alignItems="center"
      textTransform="uppercase"
      fontFamily="heading"
      size={size}
      mb={4}
    >
      {avatar && <Box mr={2}>{avatar}</Box>}
      {children}
    </Heading>
  </PseudoBox>
);

// SectionBody component prop types
export type SectionBodyProps = PseudoBoxProps;

/**
 * Wraps section body
 */
export const SectionBody: FC<SectionBodyProps> = ({
  children,
  ...rest
}: SectionBodyProps) => (
  <PseudoBox p={4} {...rest}>
    {children}
  </PseudoBox>
);

// SectionFooter component prop types
export type SectionFooterProps = PseudoBoxProps;

/**
 * Wraps section footer
 */
export const SectionFooter: FC<SectionFooterProps> = ({
  children,
  ...rest
}: SectionFooterProps) => <PseudoBox {...rest}>{children}</PseudoBox>;

// Section component prop types
export type SectionProps = StackProps;

/**
 * Top-level section component wrapper
 */
export const Section: FC<SectionProps> = ({
  children,
  ...rest
}: SectionProps) => (
  <Stack
    d={['none', null, 'flex']}
    spacing={4}
    align="center"
    w="100%"
    m="0 auto"
    color="teal.500"
    {...rest}
  >
    {children}
  </Stack>
);

export default Section;

import React, { useEffect } from 'react';
import type { FC } from 'react';
import { navigate } from '@reach/router';
import type { RouteComponentProps } from '@reach/router';

import { useParent } from 'hooks';

// Route component prop types
export interface RouteProps extends RouteComponentProps {
  component?: FC<any>;
}

/**
 * Typed, re-usable Reach Router route enabling component to render
 * to be passed as prop.
 */
export const Route: FC<RouteProps> = ({
  component: Component,
  ...rest
}: RouteProps) => (Component !== undefined ? <Component {...rest} /> : null);

/**
 * Guarantees a student has been selected before rendering a Parent page
 */
export const ParentRoute: FC<RouteProps> = ({
  component: Component,
  path,
  ...rest
}: RouteProps) => {
  // Select parent profile from ParentContext
  const { profile } = useParent()!;

  useEffect((): void => {
    // If no student has been selected, redirect to selected screen
    if (profile.students.active === undefined) {
      navigate('/parent/profile/select');
    }
  }, [profile]);

  return (
    <Route
      path={path}
      component={profile.students.active !== undefined ? Component : undefined}
      {...rest}
    />
  );
};

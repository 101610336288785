import React, { useState } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';

import { Button, IconButton, Stack } from '@chakra-ui/core';

// PaginationButtons component prop types
interface PaginationButtonsProps {
  current: number;
  count: number;
  pageSize: number;
  setCurrent: Dispatch<SetStateAction<number>>;
}

/**
 * Buttons controlling pagination of table data
 */
const PaginationButtons: FC<PaginationButtonsProps> = ({
  current,
  count,
  pageSize,
  setCurrent,
}: PaginationButtonsProps) => {
  const [page, setPage] = useState<{
    current: number;
    range: number[];
  }>({
    current: 1,
    range: Array.from(
      { length: Math.min(Math.ceil(count / pageSize), 3) },
      (_, k) => k + 1
    ),
  });

  /**
   * Render next page
   */
  const incrementPage: VoidFunction = () => {
    const next = Math.min(current + pageSize, count - 1);
    if (next < count) {
      const rangeEnd = page.range[page.range.length - 1];

      setCurrent(next);
      setPage({
        current: page.current + 1,
        range:
          page.current + 1 > rangeEnd
            ? Array.from(
                { length: Math.min(Math.ceil((count - next) / pageSize), 3) },
                (_, k) => k + rangeEnd + 1
              )
            : page.range,
      });
    }
  };

  /**
   * Render previous page
   */
  const decrementPage: VoidFunction = () => {
    const next = Math.max(current - pageSize, 0);
    if (next >= 0) {
      const rangeStart = page.range[0];

      setCurrent(next);
      setPage({
        current: page.current - 1,
        range:
          page.current - 1 < rangeStart
            ? Array.from({ length: 3 }, (_, k) => k + rangeStart - 3)
            : page.range,
      });
    }
  };

  return (
    <Stack
      isInline
      color="teal.500"
      align="center"
      justify="center"
      spacing={0}
    >
      <IconButton
        aria-label="paginate-left"
        variant="ghost"
        icon="chevron-left"
        onClick={decrementPage}
        isDisabled={current === 0}
      />
      <Stack isInline spacing={0}>
        {page.range.map((p) => (
          <Button
            variant="link"
            fontWeight="normal"
            key={p}
            pointerEvents={p === page.current ? 'none' : undefined}
            _hover={{
              textDecoration: p === page.current ? 'none' : 'underline',
            }}
            tabIndex={p === page.current ? -1 : 0}
            color={p === page.current ? 'orange.500' : 'teal.500'}
            onClick={(): void => {
              if (p !== page.current) {
                setCurrent(
                  p > current
                    ? Math.min((p - 1) * pageSize, count - 1)
                    : Math.max((p - 1) * pageSize, 0)
                );
                setPage({ ...page, current: p });
              }
            }}
          >
            {p}
          </Button>
        ))}
      </Stack>
      <IconButton
        aria-label="paginate-right"
        variant="ghost"
        icon="chevron-right"
        onClick={incrementPage}
        isDisabled={current + pageSize >= count}
      />
    </Stack>
  );
};

export default PaginationButtons;

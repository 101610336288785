import React from 'react';
import type { FC } from 'react';

import { Icon, Select } from '@chakra-ui/core';
import type { SelectProps } from '@chakra-ui/core';

// EinsteinSelect component prop types
export type EinsteinSelectProps = SelectProps;

/**
 * Custom styled Chakra-UI Input component
 */
const EinsteinSelect: FC<EinsteinSelectProps> = ({
  ...rest
}: EinsteinSelectProps) => (
  <Select
    _placeholder={{ opacity: 0.36 }}
    focusBorderColor="orange.500"
    lineHeight="normal"
    borderColor="gray.500"
    rounded={8}
    icon={() => (
      <Icon name="chevron-down" color="teal.500" w="1.25rem" h="1.25rem" />
    )}
    _hover={{ borderColor: 'gray.700' }}
    {...rest}
  />
);

export default EinsteinSelect;

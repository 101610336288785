import React from 'react';
import type { FC, ReactElement, ReactNode } from 'react';
import type { WindowLocation } from '@reach/router';
import { Link as GatsbyLink } from 'gatsby';
import type { GatsbyLinkProps } from 'gatsby';

import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  IconButton,
  Link,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import type { ButtonProps } from '@chakra-ui/core';
import { FaBars } from 'react-icons/fa';

import { NavLink } from 'components/_lib';
import { useParent, useTeacher } from 'hooks';
import { PARENT_LINKS, TEACHER_LINKS } from './links';

// DrawerButton component prop types
interface DrawerButtonProps extends ButtonProps {
  children: ReactNode;
}

/**
 *  Reusable drawer button component
 */
const DrawerButton: FC<DrawerButtonProps> = ({
  children,
  ...rest
}: DrawerButtonProps) => (
  <Button variant="link" w="100%" color="teal.500" {...rest}>
    {children}
  </Button>
);

// DrawerButtonLink component prop types
type DrawerButtonLinkProps = GatsbyLinkProps<HTMLElement> & DrawerButtonProps;

/**
 * DrawerButton with forwarded Gatsby Link
 */
const DrawerButtonLink: FC<DrawerButtonLinkProps> = ({
  children,
  ...rest
}: DrawerButtonLinkProps) => (
  <DrawerButton as={GatsbyLink as any} color="teal.500" {...rest}>
    {children}
  </DrawerButton>
);

const DIVIDER = <Box borderBottom="2px" w="90%" mx="auto" />;

// DrawerBody component prop types
interface DrawerBodyProps {
  pathname: WindowLocation['pathname'];
}

/**
 * Parent side drawer menu options
 */
const ParentDrawerBody: FC<DrawerBodyProps> = ({
  pathname,
}: DrawerBodyProps) => {
  const {
    profile: { students },
    logout,
    setActiveStudent,
  } = useParent()!;

  const active = students.all.find(({ id }) => id === students.active)!;
  const other = students.all.filter(
    (student) => student.id !== students.active
  );

  return (
    <Stack spacing={8} shouldWrapChildren>
      <Stack spacing={2} align="center" justify="center">
        <Avatar size="xl" name={active.username} src={active.picture} />
        <Text fontSize="md" fontWeight="bold">
          {active.username}
        </Text>
      </Stack>
      {PARENT_LINKS.map(
        ({ to, text, match, icon }): ReactElement => (
          <Stack key={to} isInline lineHeight={1} justify="center">
            {icon}
            <NavLink to={to} active={pathname.startsWith(match)}>
              {text}
            </NavLink>
          </Stack>
        )
      )}
      {DIVIDER}
      {other.length > 0 && (
        <Stack spacing={8} shouldWrapChildren>
          {other.map((student) => (
            <DrawerButton
              key={student.id}
              onClick={(): void => setActiveStudent(student.id)}
            >
              <Avatar
                size="md"
                name={student.username}
                src={student.picture}
                mr="12px"
              />
              <Text>{student.username}</Text>
            </DrawerButton>
          ))}
          {DIVIDER}
        </Stack>
      )}
      <DrawerButtonLink to="/parent/profile/manage">
        Manage Profile
      </DrawerButtonLink>
      <DrawerButtonLink to="/parent/unavailability">
        Unavailability
      </DrawerButtonLink>
      <DrawerButtonLink to="/parent/referral">Referral Link</DrawerButtonLink>
      {DIVIDER}
      <DrawerButton onClick={logout}>Log Out</DrawerButton>
    </Stack>
  );
};

/**
 * Teacher side drawer menu options
 */
const TeacherDrawerBody: FC<DrawerBodyProps> = ({
  pathname,
}: DrawerBodyProps) => {
  const { profile, logout } = useTeacher()!;

  return (
    <Stack spacing={8} textAlign="center" shouldWrapChildren>
      <Stack spacing={2} align="center" justify="center" textAlign="center">
        <Avatar
          size="xl"
          name={profile.fullName}
          src={profile.picture || undefined}
        />
        <Text fontSize="md" fontWeight="bold">
          {profile.fullName}
        </Text>
      </Stack>
      {TEACHER_LINKS.map(
        ({ to, text, match, icon }): ReactElement => (
          <Stack key={to} isInline lineHeight={1} justify="center">
            {icon}
            <NavLink to={to} active={pathname.startsWith(match)}>
              <Text>{text}</Text>
            </NavLink>
          </Stack>
        )
      )}
      {DIVIDER}
      <DrawerButtonLink to="/teacher/profile">Manage Profile</DrawerButtonLink>
      {profile.team === 'Einstein Studios' && (
        <Link
          href={`${process.env.WEB_API_URL}/teachers/user/stripe`}
          isExternal
        >
          <DrawerButton>Stripe Account</DrawerButton>
        </Link>
      )}
      {DIVIDER}
      <DrawerButton onClick={logout}>Log Out</DrawerButton>
    </Stack>
  );
};

// SideDrawer component prop types
interface SideDrawerProps {
  type: 'parent' | 'teacher';
  pathname: WindowLocation['pathname'];
}

/**
 * Side navigation drawer for mobile
 */
const SideDrawer: FC<SideDrawerProps> = ({
  type,
  pathname,
}: SideDrawerProps) => {
  // SideDrawer open/closed state and actions
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Box d={['block', null, 'none']}>
      <IconButton
        aria-label="Navigation Menu"
        fontSize="20px"
        variant="ghost"
        icon={(): ReactElement => <Box as={FaBars} color="teal.500" />}
        onClick={onToggle}
      />
      <Drawer size="xs" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody py={8} overflowY="auto" color="teal.500">
            {type === 'parent' ? (
              <ParentDrawerBody pathname={pathname} />
            ) : (
              <TeacherDrawerBody pathname={pathname} />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default SideDrawer;

import React from 'react';
import type { FC } from 'react';

import { Heading, Stack } from '@chakra-ui/core';

import withSEO from 'components/_hoc/withSEO';
import { ButtonChakraLink } from 'components/_lib/Links';

/**
 * Catch-all error page
 */
const ErrorPage: FC = () => (
  <Stack
    h="100vh"
    w="100vw"
    align="center"
    justify="center"
    spacing={8}
    color="teal.500"
  >
    <Heading size="lg">An Error Occurred</Heading>
    <Heading size="md">This one&apos;s on us, we apologize.</Heading>
    <ButtonChakraLink href={process.env.LANDING_PAGE_URL} size="lg">
      Return to Website
    </ButtonChakraLink>
  </Stack>
);

export default withSEO(ErrorPage, {
  title: 'An Error Occurred',
});

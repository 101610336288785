import React, { forwardRef } from 'react';
import type { ReactElement, Ref } from 'react';

import { Button } from '@chakra-ui/core';
import type { ButtonProps } from '@chakra-ui/core';

// EinsteinRadioButton component prop types
export interface EinsteinRadioButtonProps extends ButtonProps {
  value?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
}

/**
 * Custom styled Chakra-UI radio button
 */
const EinsteinRadioButton = (
  { isChecked, isDisabled, children, ...rest }: EinsteinRadioButtonProps,
  ref: Ref<HTMLButtonElement>
): ReactElement => (
  <Button
    ref={ref}
    h="50px"
    w="125px"
    borderRadius={8}
    variantColor={isChecked ? 'orange' : 'teal'}
    variant={isChecked ? 'solid' : 'outline'}
    aria-checked={isChecked}
    role="radio"
    isDisabled={isDisabled}
    {...rest}
  >
    {children}
  </Button>
);

export default forwardRef(EinsteinRadioButton);

import React from 'react';
import type { FC } from 'react';

import { Heading, Stack } from '@chakra-ui/core';
import type { HeadingProps, StackProps } from '@chakra-ui/core';

// OnboardingSectionTitle component prop types
export type OnboardingSectionTitleProps = HeadingProps;

/**
 * Styled title for OnboardingSection
 */
export const OnboardingSectionTitle: FC<OnboardingSectionTitleProps> = ({
  children,
  ...rest
}: OnboardingSectionTitleProps) => (
  <Heading
    textAlign={['center', null, 'left']}
    fontSize="lg"
    w="100%"
    {...rest}
  >
    {children}
  </Heading>
);

// OnboardingSectionBody component prop types
export type OnboardingSectionBodyProps = StackProps;

/**
 * Styled content container for OnboardingSection
 */
export const OnboardingSectionBody: FC<OnboardingSectionBodyProps> = ({
  children,
  ...rest
}: OnboardingSectionBodyProps) => (
  <Stack spacing={8} align="center" justify="center" w="100%" {...rest}>
    {children}
  </Stack>
);

// OnboardingSection component prop types
export type OnboardingSectionProps = StackProps;

/**
 * Container wrapping on-boarding content
 */
export const OnboardingSection: FC<OnboardingSectionProps> = ({
  children,
  ...rest
}: OnboardingSectionProps) => (
  <Stack
    align="center"
    justify="center"
    spacing={8}
    p={4}
    w="100%"
    color="teal.500"
    {...rest}
  >
    {children}
  </Stack>
);

export default OnboardingSection;

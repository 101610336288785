import React from 'react';
import type { FC } from 'react';

import { Box, Grid } from '@chakra-ui/core';

/**
 * Creates a new component that wraps received component
 * with grid spacing.
 *
 * @param WrappedComponent - Component to wrap with grid spacing.
 *
 * Used to fill replicate BreadcrumbNav space for pages not having it
 */
const withSpacing = <WrappedComponentProps extends {}>(
  WrappedComponent: FC<WrappedComponentProps>
): FC<WrappedComponentProps> => {
  const WithSpacing: FC<WrappedComponentProps> = ({
    ...wrappedComponentProps
  }: WrappedComponentProps) => (
    <Grid
      gridTemplateRows={['none', null, '4rem auto 4rem']}
      gridTemplateColumns={['0 auto 0', null, 'none']}
      minH={[
        'calc(100vh - 164px - 4rem)',
        'calc(100vh - 168px - 4rem)',
        'calc(100vh - 172px - 12rem)',
        'calc(100vh - 180px - 12rem)',
      ]}
    >
      <Box />
      <WrappedComponent {...wrappedComponentProps} />
      <Box />
    </Grid>
  );

  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithSpacing.displayName = `withSpacing(${wrappedComponentName})`;
  return WithSpacing;
};

export default withSpacing;

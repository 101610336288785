import React, { createContext, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { navigate } from 'gatsby';
import { format, getDay, parseISO, setDay } from 'date-fns';
import { convertToLocalTime, convertToTimeZone } from 'date-fns-timezone';

import { WeekdaysEnum } from 'data';
import { useAuth } from 'hooks';
import type { Availability, Parent, Subject, Teacher, Weekday } from 'types';

const DAYS_OF_WEEK: Weekday[] = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

/**
 * Converts zoned availability data into the local timezone.
 *
 * @param availability - Details of availability to convert.
 */
const localizeAvailability = ({
  dayOfWeek,
  startTime,
  timeZone,
}: Pick<Availability, 'dayOfWeek' | 'startTime'> & {
  timeZone: Teacher['timezone'];
}): Date => {
  const dayInNeed = WeekdaysEnum[dayOfWeek];
  const zonedNow = convertToTimeZone(Date.now(), { timeZone });
  const zonedAvailability = setDay(
    parseISO(`${format(zonedNow, 'yyyy-MM-dd')} ${startTime}`),
    dayInNeed
  );

  return convertToLocalTime(zonedAvailability, { timeZone });
};

// Profile type used in ParentContextType
interface ParentProfile extends Omit<Parent, 'students'> {
  students: {
    all: Parent['students']; // Array of parent's students
    active?: string; // ID of current selected student
  };
}

// Parent context type
export interface ParentContextType {
  profile: ParentProfile;
  setActiveStudent: (id: string) => void;
  logout: VoidFunction;
}

// Instantiate parent context
export const ParentContext = createContext<ParentContextType | null>(null);

// ParentProvider component prop types
interface ParentProviderProps {
  children: ReactNode;
}

/**
 * Provides React context controlling profile for authenticated parent.
 */
const ParentProvider: FC<ParentProviderProps> = ({
  children,
}: ParentProviderProps) => {
  // Select AuthContext
  const authContext = useAuth()!;
  const user = authContext.data.user as Parent;
  const activeStudent = window.localStorage.getItem('parent.activeStudent');

  // Instantiate parent profile
  const [profile, setProfile] = useState<ParentContextType['profile']>({
    ...user,
    subjects: Object.keys(user.subjects).reduce<Parent['subjects']>(
      (subjects, current) => ({
        ...subjects,
        [current]: {
          ...user.subjects[current as Subject['name']],
          bookings: user.subjects[current as Subject['name']].bookings.map(
            (booking) => {
              const localAvailability = localizeAvailability({
                dayOfWeek: booking.availability.dayOfWeek,
                startTime: booking.availability.startTime,
                timeZone: booking.teacher.timezone,
              });

              return {
                ...booking,
                availability: {
                  ...booking.availability,
                  startTime: format(localAvailability, 'HH:mm:ss'),
                  dayOfWeek: DAYS_OF_WEEK[getDay(localAvailability)],
                },
              };
            }
          ),
        },
      }),
      {} as Parent['subjects']
    ),
    students: {
      all: user.students,
      active: user.students.find((student) => student.id === activeStudent)
        ? activeStudent!
        : undefined,
    },
  });

  /**
   * Update the current active student.
   *
   * @param id - ID of the new active student
   */
  const setActiveStudent = (id: string): void => {
    setProfile({
      ...profile,
      students: { ...profile.students, active: id },
    });
    window.localStorage.setItem('parent.activeStudent', id);
    navigate('/parent/home');
  };

  // Log parent out
  const logout: VoidFunction = () => {
    window.localStorage.removeItem('parent.activeStudent');
    authContext.logout();
  };

  return (
    <ParentContext.Provider value={{ profile, setActiveStudent, logout }}>
      {children}
    </ParentContext.Provider>
  );
};

export default ParentProvider;

import React, { createContext } from 'react';
import type { FC, ReactNode } from 'react';
import { useQuery } from 'react-query';
import type { QueryFunction, QueryResult } from 'react-query';

import { Flex } from '@chakra-ui/core';

import API from 'api';
import { LoadingSpinner } from 'components/_lib';
import type { Parent, Teacher } from 'types';

// AuthContext data type
interface AuthData {
  user: Parent | Teacher;
}

// Auth context type
export interface AuthContextType {
  logout: VoidFunction;
  data: AuthData;
  refetch: QueryResult<AuthData | null>['refetch'];
}

// Instantiate auth context
export const AuthContext = createContext<AuthContextType | null>(null);

/**
 * Feteches user profile.
 *
 * @param key - React Query key.
 */
const fetchProfile: QueryFunction<
  AuthData | null,
  [string, string, string, VoidFunction]
> = async (_, dataUrl, key, logout) => {
  try {
    // Query user data from API
    const response = await API.get<Parent | Teacher>(dataUrl);
    window.localStorage.setItem(key, 'true');
    return { user: response.data };
  } catch (error) {
    // O/w, no authenticated user exists
    if (error.code !== 'ECONNABORTED') logout();
    return null;
  }
};

// AuthProvider component prop types
interface AuthProviderProps {
  children: ReactNode;
  scope: string;
  dataUrl: string; // API URL to retrieve user data
  logoutUrl: string; // API URL to log user out
}

/**
 * Provides React context controlling user instantiation and logout.
 */
export const AuthProvider: FC<AuthProviderProps> = ({
  children,
  scope,
  dataUrl,
  logoutUrl,
}: AuthProviderProps) => {
  const key = `${scope}.isAuthenticated`;

  // Submit logout request to API (destroys access cookie)
  const logout: VoidFunction = () => {
    window.localStorage.removeItem(key);
    window.location.href = logoutUrl;
  };

  const { data, status, refetch } = useQuery(
    ['authData', dataUrl],
    [key, logout],
    fetchProfile
  );

  return status === 'loading' || !data ? (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <LoadingSpinner />
    </Flex>
  ) : (
    <AuthContext.Provider value={{ logout, data, refetch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import React from 'react';
import type { FC, ReactElement } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import type { GatsbyLinkProps } from 'gatsby';

import { Heading, Icon, PseudoBox, Stack } from '@chakra-ui/core';
import type { PseudoBoxProps } from '@chakra-ui/core';

import { IconButtonGatsbyLink } from './Links';

// MobileSectionTitle component prop types
export interface MobileSectionTitleProps extends PseudoBoxProps {
  backTo?: string;
  avatar?: ReactElement;
}

/**
 * Title header for mobile section component
 */
export const MobileSectionTitle: FC<MobileSectionTitleProps> = ({
  backTo,
  avatar,
  children,
  ...rest
}: MobileSectionTitleProps) => (
  <PseudoBox
    d="flex"
    alignItems="center"
    justifyContent="center"
    p={4}
    borderBottom="1px solid"
    borderColor="gray.100"
    h="73px"
    {...rest}
  >
    {backTo && (
      <IconButtonGatsbyLink
        aria-label="return to previous page"
        icon={() => <Icon name="chevron-left" fontSize="24px" />}
        variant="ghost"
        to={backTo}
        mr="auto"
      />
    )}
    <Stack
      isInline
      spacing={4}
      alignItems="center"
      margin="0 auto"
      position="absolute"
      shouldWrapChildren
    >
      {avatar}
      <Heading
        textTransform="uppercase"
        color="teal.500"
        size="md"
        fontFamily="heading"
      >
        {children}
      </Heading>
    </Stack>
  </PseudoBox>
);

// MobileSectionGroup component prop types
export type MobileSectionGroupProps = PseudoBoxProps;

/**
 * Wrapper around mobile section items
 */
export const MobileSectionGroup: FC<MobileSectionGroupProps> = ({
  children,
  ...rest
}: MobileSectionGroupProps) => <PseudoBox {...rest}>{children}</PseudoBox>;

// MobileSectionItem component prop types
export type MobileSectionItemProps = PseudoBoxProps;

/**
 * Individual content item for mobile section component
 */
export const MobileSectionItem: FC<MobileSectionItemProps> = ({
  children,
  ...rest
}: MobileSectionItemProps) => (
  <PseudoBox
    d="flex"
    alignItems="center"
    justifyContent="space-between"
    px={[4, 8]}
    py={8}
    border="1px solid"
    borderColor="gray.100"
    {...rest}
  >
    {children}
  </PseudoBox>
);

// MobileSectionItemLink component prop types
export type MobileSectionItemLinkProps = GatsbyLinkProps<HTMLElement> &
  MobileSectionItemProps;

/**
 * MobileSectionItem with forwarded Gatsby Link
 */
export const MobileSectionItemLink: FC<MobileSectionItemLinkProps> = ({
  children,
  ...rest
}: MobileSectionItemLinkProps) => (
  <MobileSectionItem as={GatsbyLink as any} {...rest}>
    {children}
  </MobileSectionItem>
);

// MobileSectionFooter component prop types
export type MobileSectionFooterProps = PseudoBoxProps;

/**
 * Individual content item for mobile section component
 */
export const MobileSectionFooter: FC<MobileSectionFooterProps> = ({
  children,
  ...rest
}: MobileSectionFooterProps) => (
  <PseudoBox
    d="flex"
    alignItems="center"
    justifyContent="center"
    borderTop="1px solid"
    borderColor="gray.100"
    py={4}
    {...rest}
  >
    {children}
  </PseudoBox>
);

// MobileSection component prop types
export type MobileSectionProps = PseudoBoxProps;

/**
 * Top-level wrapper for mobile section, only rendered on small screens
 */
export const MobileSection: FC<MobileSectionProps> = ({
  children,
  ...rest
}: MobileSectionProps) => (
  <PseudoBox
    d={['block', null, 'none']}
    bg="white"
    rounded={8}
    m="0 auto"
    color="teal.500"
    w="100%"
    {...rest}
  >
    {children}
  </PseudoBox>
);

export default MobileSection;

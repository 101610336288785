import React from 'react';
import type { FC } from 'react';

import { Radio } from '@chakra-ui/core';
import type { RadioProps } from '@chakra-ui/core';
import styled from '@emotion/styled';

import colors from 'theme/colors';

const StyledRadio = styled(Radio)`
  div[type='radio'] {
    border: 1px solid ${colors.gray['500']};
    background: ${colors.white};
  }
`;

// EinsteinRadio component prop types
export type EinsteinRadioProps = RadioProps;

/**
 * Custom styled Chakra-UI Radio component
 */
const EinsteinRadio: FC<EinsteinRadioProps> = ({
  children,
  ...rest
}: EinsteinRadioProps) => <StyledRadio {...rest}>{children}</StyledRadio>;

export default EinsteinRadio;

import React from 'react';
import type { FC } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import type { GatsbyLinkProps } from 'gatsby';

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/core';
import type { LinkProps } from '@chakra-ui/core';

// ReachBreadcrumbLink component prop types
type ReachBreadcrumbLinkProps = GatsbyLinkProps<HTMLAnchorElement> & LinkProps;

/**
 * Chakra-UI breadcrumb link with forwarded Gatsby Link
 */
const ReachBreadcrumbLink: FC<ReachBreadcrumbLinkProps> = ({
  ...rest
}: ReachBreadcrumbLinkProps) => {
  return <BreadcrumbLink as={GatsbyLink as any} {...rest} />;
};

// Breadcrumb link type
export interface BreadcrumbNavLink {
  href: string;
  text: string;
  isCurrentPage: boolean;
}

// BreadcrumbNav component prop types
export interface BreadcrumbNavProps {
  links: BreadcrumbNavLink[];
}

/**
 * Breadcrumb navigation
 */
const BreadcrumbNav: FC<BreadcrumbNavProps> = ({
  links,
}: BreadcrumbNavProps) => (
  <Breadcrumb d={['none', null, 'block']} h={16} color="teal.500">
    {links.map(({ href, text, isCurrentPage }, index) => (
      <BreadcrumbItem key={href} isCurrentPage={isCurrentPage}>
        <ReachBreadcrumbLink
          to={href}
          pointerEvents={index === links.length - 1 ? 'none' : undefined}
          tabIndex={index === links.length - 1 ? -1 : undefined}
        >
          {text}
        </ReachBreadcrumbLink>
      </BreadcrumbItem>
    ))}
  </Breadcrumb>
);

export default BreadcrumbNav;

import React from 'react';
import type { FC } from 'react';

import { Textarea } from '@chakra-ui/core';
import type { InputProps } from '@chakra-ui/core';
import styled from '@emotion/styled';

import colors from 'theme/colors';

// Custom styled Chakra-UI Textarea
const StyledTextarea = styled(Textarea)`
  &::placeholder {
    color: ${colors.teal['500']};
    opacity: 0.36;
  }
`;

// EinsteinTextarea component prop types
export type EinsteinTextareaProps = InputProps<HTMLTextAreaElement>;

/**
 * Custom styled Chakra-UI textarea component
 */
const EinsteinTextarea: FC<EinsteinTextareaProps> = ({
  children,
  ...rest
}: EinsteinTextareaProps) => (
  <StyledTextarea
    focusBorderColor="orange.500"
    borderColor="gray.500"
    _hover={{ borderColor: 'gray.700' }}
    borderRadius={8}
    {...rest}
  >
    {children}
  </StyledTextarea>
);

export default EinsteinTextarea;

import React from 'react';
import type { FC, ReactElement } from 'react';
import type { WindowLocation } from '@reach/router';
import { Link as GatsbyLink } from 'gatsby';
import type { GatsbyLinkProps } from 'gatsby';

import {
  Avatar,
  Button,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/core';
import type {
  ButtonProps,
  MenuButtonProps,
  MenuItemProps,
} from '@chakra-ui/core';

import { useParent, useTeacher } from 'hooks';
import { NavLink } from 'components/_lib';

import { PARENT_LINKS, TEACHER_LINKS } from './links';

// MenuItemLink component prop types
type MenuItemLinkProps = GatsbyLinkProps<HTMLElement> & MenuItemProps;

/**
 * Chakra-UI MenuItem with forwarded Gatsby Link
 */
const MenuItemLink: FC<MenuItemLinkProps> = ({
  children,
  ...rest
}: MenuItemLinkProps) => (
  <MenuItem as={GatsbyLink as any} {...rest}>
    {children}
  </MenuItem>
);

/**
 * Chakra-UI MenuButton with forwarded Button
 */
const MenuButtonButton: FC<MenuButtonProps & ButtonProps> = ({
  children,
  ...rest
}: MenuButtonProps & ButtonProps) => (
  <MenuButton
    as={Button}
    d="flex"
    alignItems="center"
    h="max-content"
    w="min-content"
    p="0.25rem"
    rightIcon={(): ReactElement => (
      <Icon name="chevron-down" size="22px" color="teal.500" />
    )}
    {...rest}
  >
    {children}
  </MenuButton>
);

/**
 * Parent dropdown menu
 */
const ParentDropdownMenu: FC<{}> = () => {
  const {
    profile: { students },
    logout,
    setActiveStudent,
  } = useParent()!;

  const active = students.all.find(({ id }) => id === students.active)!;
  const other = students.all.filter(
    (student) => student.id !== students.active
  );

  return (
    <Menu>
      <MenuButtonButton variant="ghost">
        <Avatar src={active.picture} />
      </MenuButtonButton>
      <MenuList color="teal.500">
        {other.length > 0 && (
          <>
            {other.map((student) => (
              <MenuItem
                key={student.id}
                minH="48px"
                onClick={(): void => setActiveStudent(student.id)}
              >
                <Avatar
                  size="sm"
                  name={student.username}
                  src={student.picture}
                  mr="12px"
                />
                <Text>{student.username}</Text>
              </MenuItem>
            ))}
            <MenuDivider />
          </>
        )}
        <MenuItemLink to="/parent/profile/manage">Manage Profile</MenuItemLink>
        <MenuItemLink to="/parent/unavailability">Unavailability</MenuItemLink>
        <MenuItemLink to="/parent/referral">Referral Link</MenuItemLink>
        <MenuDivider />
        <MenuItem onClick={logout}>Log Out</MenuItem>
      </MenuList>
    </Menu>
  );
};

/**
 * Teacher dropdown menu
 */
const TeacherDropdownMenu: FC<{}> = () => {
  const { profile, logout } = useTeacher()!;

  return (
    <Menu>
      <MenuButtonButton variant="ghost">
        <Avatar src={profile.picture || undefined} />
      </MenuButtonButton>
      <MenuList color="teal.500">
        <MenuItemLink to="/teacher/profile">Manage Profile</MenuItemLink>
        {profile.team === 'Einstein Studios' && (
          <Link
            href={`${process.env.WEB_API_URL}/teachers/user/stripe`}
            isExternal
            _hover={{}}
          >
            <MenuItem>Stripe Account</MenuItem>
          </Link>
        )}
        <MenuDivider />
        <MenuItem onClick={logout}>Log Out</MenuItem>
      </MenuList>
    </Menu>
  );
};

// NavMenu component prop types
interface NavMenuProps {
  type: 'parent' | 'teacher';
  pathname: WindowLocation['pathname'];
}

/**
 * Navigation menu rendered on desktop
 */
const NavMenu: FC<NavMenuProps> = ({ type, pathname }: NavMenuProps) => (
  <Stack d={['none', null, 'flex']} isInline spacing={8} align="center">
    <Stack isInline spacing={8} align="center">
      {(type === 'parent' ? PARENT_LINKS : TEACHER_LINKS).map(
        ({ to, text, match, icon }): ReactElement => (
          <Stack key={to} isInline lineHeight={1} align="center">
            {icon}
            <NavLink to={to} active={pathname.startsWith(match)}>
              <Text>{text}</Text>
            </NavLink>
          </Stack>
        )
      )}
    </Stack>
    {type === 'parent' ? <ParentDropdownMenu /> : <TeacherDropdownMenu />}
  </Stack>
);

export default NavMenu;

import React from 'react';
import type { FC } from 'react';
import { useLocation } from '@reach/router';

import { Box, Flex, Icon } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';

import EinsteinStudiosLogoFlat from 'assets/svgs/einstein-studios-logo-flat.svg';
import EinsteinStudiosLogoUpright from 'assets/svgs/einstein-studios-logo-upright.svg';
import PrideLogoFlat from 'assets/svgs/pride-logo-flat.svg';
import PrideLogoUpright from 'assets/svgs/pride-logo-upright.svg';
import { RouterLink } from 'components/_lib';
import useParent from 'hooks/useParent';
import useTeacher from 'hooks/useTeacher';

import NavMenu from './NavMenu';
import SideDrawer from './SideDrawer';

// NavbarContainer component prop types
type NavbarContainerProps = Omit<FlexProps, 'children'>;

/**
 * Wraps fixed top navbar
 */
const NavbarContainer: FC<NavbarContainerProps> = ({
  ...rest
}: NavbarContainerProps) => {
  const { pathname } = useLocation();
  const type = pathname.startsWith('/parent') ? 'parent' : 'teacher';

  const parent = useParent();
  const teacher = useTeacher();
  const isPride =
    parent?.profile.subjects.Literacy.team === 'PRIDE' ||
    teacher?.profile.team === 'PRIDE';

  return (
    <Flex
      as="nav"
      w="100%"
      px={[4, 8, 16, 32]}
      align="center"
      justify="space-between"
      {...rest}
    >
      <RouterLink
        d="flex"
        alignItems="center"
        flexDir="row"
        to={`/${type}/home`}
      >
        <Flex d={isPride ? ['none', 'flex', 'none', 'flex'] : 'flex'} w="140px">
          <EinsteinStudiosLogoFlat />
        </Flex>
        {isPride && (
          <>
            <Box d={['block', 'none', 'block', 'none']} w="50px">
              <EinsteinStudiosLogoUpright />
            </Box>
            <Icon name="add" color="orange.500" ml={4} mr={2} />
            <Flex d={['none', 'flex', 'none', 'flex']} w="165px" mb={4}>
              <PrideLogoFlat />
            </Flex>
            <Flex d={['flex', 'none', 'flex', 'none']} w="75px" mb={1}>
              <PrideLogoUpright />
            </Flex>
          </>
        )}
      </RouterLink>

      {/* Side drawer, rendered on mobile */}
      <SideDrawer type={type} pathname={pathname} />

      {/* Navbar links, rendered on desktop */}
      <NavMenu type={type} pathname={pathname} />
    </Flex>
  );
};

export default NavbarContainer;

import type { Student } from 'types';
import useParent from './useParent';

/**
 * Select the current selected student from the ParentContext profile
 */
const useActiveStudent = (): Student => {
  const {
    profile: { students },
  } = useParent()!;

  return students.all.find(({ id }) => id === students.active)!;
};

export default useActiveStudent;

import React from 'react';
import type { ReactElement } from 'react';
import { Icon } from '@chakra-ui/core';

// Navigation link type
export interface PageLink {
  to: string;
  text: string;
  match: string;
  icon: ReactElement;
}

// Links for parent dashboard
export const PARENT_LINKS: PageLink[] = [
  {
    to: '/parent/home',
    text: 'Home',
    match: '/parent/home',
    icon: <Icon name="house" />,
  },
  {
    to: '/parent/classes',
    text: 'Classes',
    match: '/parent/classes',
    icon: <Icon name="flask" />,
  },
  {
    to: '/parent/subscriptions/manage',
    text: 'Subscription',
    match: '/parent/subscriptions',
    icon: <Icon name="dollar-sign-circle" />,
  },
];

// Links for teacher dashboard
export const TEACHER_LINKS: PageLink[] = [
  {
    to: '/teacher/home',
    text: 'Home',
    match: '/teacher/home',
    icon: <Icon name="house" />,
  },
  {
    to: '/teacher/schedule',
    text: 'Schedule',
    match: '/teacher/schedule',
    icon: <Icon name="calendar" color="teal.500" />,
  },
];

import React from 'react';
import type { FC } from 'react';

import { Box, Flex, Link, Stack } from '@chakra-ui/core';
import type { FlexProps } from '@chakra-ui/core';
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoTwitter,
} from 'react-icons/io';

import EinsteinStudiosLogoBubble from 'assets/svgs/einstein-studios-logo-bubble.svg';

// Footer component prop types
type FooterProps = Omit<FlexProps, 'children'>;

/**
 * Fixed bottom footer
 */
const Footer: FC<FooterProps> = (props: FooterProps) => (
  <Flex
    w="100%"
    align="center"
    justify="center"
    px={[4, 8, 16, 32]}
    color="teal.500"
    {...props}
  >
    <Flex
      flexDirection={['column', null, 'row']}
      align="center"
      justify="space-between"
      w="100%"
    >
      <Stack isInline spacing={3} align="center">
        <Link isExternal href={process.env.LANDING_PAGE_URL} w="18px">
          <EinsteinStudiosLogoBubble />
        </Link>
        <Link fontSize="sm" isExternal href="/terms-of-service.pdf">
          Terms
        </Link>
        <Link fontSize="sm" isExternal href="/privacy-policy.pdf">
          Privacy
        </Link>
        <Link fontSize="sm" isExternal href="mailto:albert@einsteinstudios.io">
          Contact
        </Link>
      </Stack>
      <Stack isInline spacing={2} mt={[2, null, 0]}>
        <Link isExternal href="https://www.facebook.com/EinsteinStudiosVR">
          <Box as={IoLogoFacebook} size="20px" color="orange.500" />
        </Link>
        <Link isExternal href="https://twitter.com/StudiosEinstein">
          <Box as={IoLogoTwitter} size="20px" color="orange.500" />
        </Link>
        <Link isExternal href="https://www.instagram.com/einstein.studios/">
          <Box as={IoLogoInstagram} size="20px" color="orange.500" />
        </Link>
        <Link
          isExternal
          href="https://www.linkedin.com/company/einstein-studios/"
        >
          <Box as={IoLogoLinkedin} size="20px" color="orange.500" />
        </Link>
      </Stack>
    </Flex>
  </Flex>
);

export default Footer;

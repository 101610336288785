import React from 'react';
import type { FC } from 'react';
import { navigate } from 'gatsby';

import { useParent } from 'hooks';
import withLayout from './withLayout';

/**
 * Creates a new component that verifies a parent subscription
 * before rendering a wrapped component.
 *
 * @param WrappedComponent - Component to wrap with SEO.
 */
const withSubscription = <WrappedComponentProps extends {}>(
  WrappedComponent: FC<WrappedComponentProps>
): FC<WrappedComponentProps> => {
  const WithSubscription: FC<WrappedComponentProps> = ({
    ...wrappedComponentProps
  }: WrappedComponentProps) => {
    const { profile } = useParent()!;

    if (
      Object.values(profile.subjects).find(
        ({ subscription }) => subscription !== undefined
      ) === undefined &&
      window.location.pathname.includes('/subscriptions/manage')
    ) {
      navigate('/parent/subscriptions/purchase', { replace: true });
      return null;
    }

    return <WrappedComponent {...wrappedComponentProps} />;
  };

  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithSubscription.displayName = `withSubscription(${wrappedComponentName})`;
  return withLayout(WithSubscription);
};

export default withSubscription;

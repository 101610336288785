import React from 'react';
import type { FC } from 'react';
import styled from '@emotion/styled';
import colors from 'theme/colors';

interface StyledTableElementProps {
  display?: string;
}

const StyledTable = styled.table<StyledTableElementProps>(
  ({ display = 'table' }: StyledTableElementProps) => ` 
  border-collapse: collapse;
  color: ${colors.teal['500']};
  display: ${display};
  flex-direction: column;
  text-align: center;
  width: 100%;
  `
);

const StyledHead = styled.thead`
  border-bottom: 2px solid ${colors.gray['500']};
`;

const StyledTR = styled.tr<StyledTableElementProps>(
  ({ display = 'table-row' }: StyledTableElementProps) => `  
  border-bottom: 1px solid ${colors.gray['500']};
  display: ${display};

  &:last-child {
    border-bottom: none;
  }
`
);

const StyledTH = styled.th`
  padding: 16px;
`;

const StyledTD = styled.td`
  padding: 24px;
}
`;

// EinsteinTable component prop types
export interface EinsteinTableProps {
  columns: string[];
  data: Array<any[]>;
}

/**
 * Custom styled HTML table
 */
const EinsteinTable: FC<EinsteinTableProps> = ({
  columns,
  data,
}: EinsteinTableProps) => (
  /* eslint-disable react/no-array-index-key */
  <StyledTable>
    <StyledHead>
      <StyledTR>
        {columns.map((c) => (
          <StyledTH key={c}>{c}</StyledTH>
        ))}
      </StyledTR>
    </StyledHead>
    <tbody>
      {data.map((row, r) => (
        <StyledTR key={r}>
          {row.map((column, c) => (
            <StyledTD key={`${r}-${c}`}>{column}</StyledTD>
          ))}
        </StyledTR>
      ))}
    </tbody>
  </StyledTable>
  /* eslint-enable react/no-array-index-key */
);

export default EinsteinTable;
